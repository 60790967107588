import React, { Fragment } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

import dd from "../assets/img/newupdated/first.png";
import second from "../assets/img/newupdated/second.png";
import third from "../assets/img/newupdated/Rectangle 140 (2).png";
import pic1 from "../assets/img/jer2/cooler-provide.jpg";
import pic2 from "../assets/img/jer2/cooler-provide1.jpg";
import pic3 from "../assets/img/jer/17.jpg";
import pic4 from "../assets/img/jer/WhatsApp Image 2023-08-20 at 5.30.40 AM.jpeg";
import pic5 from "../assets/img/jer/WhatsApp Image 2023-08-20 at 5.31.03 AM.jpeg";
import pic6 from "../assets/img/jer/WhatsApp Image 2023-08-20 at 5.30.50 AM.jpeg";

import { Link } from "react-router-dom";
const Quenching = () => {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const data = [pic1, pic3, pic2];
  return (
    <div style={{ backgroundColor: "#f5f5f5" }}>
      <div className="container  pb-md-5">
        <div className="container Success mb-5">
          {/* Provide cooler */}
          <h2>Quenching Thirst and spreading Kindness </h2>
          <p style={{ fontSize: "22px", letterSpacing: "2px" }}>
            People in villages have been struggling with a lack of access to
            basic facilities for far too long. Families, loaded by financial
            hardships, find it challenging to meet their daily needs, especially
            during the sweating summer months when hydration is of utmost
            importance. SSF endeavors to compensate these people in this need.
          </p>
          <div className="d-flex">
            <Link to="/give/samsahotra">
              <button
                className="paypal_donate mt-2"
                style={{ background: "#4D7C8A" }}
              >
                Give Now
              </button>
            </Link>
            <Link
              to="/Quenching"
              onClick={scrollTop}
              style={{ textDecoration: "none" }}
            >
              {" "}
              <button className="paypal_donate mt-2">Read More </button>
            </Link>
          </div>
        </div>
        <Container className=" pt-5 mt-4 mb-4 ">
          <Row className=" detail-content-box ">
            {data?.map((photo, i) => {
              return (
                <>
                  <Col md={4} className="">
                    <div>
                      <div>
                        <img
                          src={photo}
                          className="card-img-top mt-3"
                          height={260}
                          loading="lazy"
                          alt="Sam Sahotra Foundation"
                        />
                      </div>
                    </div>
                  </Col>
                </>
              );
            })}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Quenching;
